.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.buttonStyleDownloadExcel {
  /* width: 215px; */
  width: 100%;
  // height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #007bff;  //#3f51b5;
  border-width: 0px;
  text-align: center;
  // padding-left: 29px;  
  padding: 7px;
}

.buttonStyleDownloadExcel:hover {
  /* width: 215px; */
  width: 100%;
  // height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #0069D9;  //#3750e0;
  border-width: 0px;
  text-align: center;
  // padding-left: 29px;  
  padding: 7px;
  cursor: pointer;
}

.buttonStyleDownloadExcel-event {
  width: 275px;
  /* width: 100%; */
  height: 40px;
  /* font-size: 1rem; */
  /* line-height: 1.5; */
  /* padding: .375rem .75rem; */
  border-radius: 5px;
  /* font-size: 15px; */
  color: #ffffff;
  background-color: #007bff;  //#007bff;
  border-width: 1px;
  border-color: white;
  text-align: center;
  /* padding-left: 27px;   */
  vertical-align: middle;
}

.buttonStyleDownloadExcel-event:hover {
  width: 275px;
  /* width: 100%; */
  height: 40px;
  /* font-size: 1rem; */
  /* line-height: 1.5; */
  /* padding: .375rem .75rem; */
  border-radius: 5px;
  /* font-size: 15px; */
  color: #ffffff;
  background-color: #007bff;  //#3750e0;
  border-width: 1px;
  border-color: white;
  text-align: center;
  /* padding-left: 27px;   */
  cursor: pointer;
}

.buttonStyleDownloadExcelSimple {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #007bff;  //#3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 10px;   */
}

.buttonStyleDownloadExcelSimple:hover {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #007bff;  //#3750e0;
  border-width: 0px;
  text-align: center;
  /* padding-left: 10px;   */
  cursor: pointer;
}

.btn-sort {
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  /* padding-bottom: 5px; */
  border: 1px solid blueviolet;
  border-radius: 15%;
}

.buttonStyleCloseUi {
  width: 115px;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #007bff;  //#3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 29px;   */
}

.buttonStyleEnterAdminUi {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  background-color: #007bff;  //#3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 29px;   */
}

// .col-evt-btn {
//   margin-left: 0px;
//   margin-right: 0px;
// }

.tb-row-hover tr:hover {
  background-color: aqua;
}

.tb-col-hover td:hover {
  background-color: aqua;
}
