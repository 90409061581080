.App {
  text-align: center;
  /* background-image: url('./Screens/Quiz/images/Background.jpg'); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.floating-modal {
  position: fixed;
  width: 185px;
  height: 35px;
  left: 0px;
  top: 25px;
  z-index: 100;
  font-weight: bold;
  color: yellow;
  text-align: center;

  text-shadow: 
        -1px -1px 0px #000,
         0px -1px 0px #000,
         1px -1px 0px #000,
        -1px  0px 0px #000,
         1px  0px 0px #000,
        -1px  1px 0px #000,
         0px  1px 0px #000,
         1px  1px 0px #000;
}

/* .card {
  padding: 0px;
  margin: 0px;
}

.card-body {
  padding: 0px;
  margin: 0px;
} */


/* @group Blink - 2020.12.16 */
.blink {
	-webkit-animation: blink 1.5s linear infinite;
	-moz-animation: blink 1.5s linear infinite;
	-ms-animation: blink 1.5s linear infinite;
	-o-animation: blink 1.5s linear infinite;
	 animation: blink 1.5s linear infinite;
}
@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
/* @end */

/* @group borderBlink for div - 2021.02.23 */
@-webkit-keyframes borderBlink {    
  from, to {    
      border-color: transparent    
  }    
  50% {    
      border-color: red    
  }    
}    
@keyframes borderBlink {    
  from, to {    
      border-color: transparent    
  }    
  50% {    
      border-color: red    
  }    
}    
.borderBlink {    
  border:3px solid red;
  /* add 'border-color: transparent' if you wish no border to show initially */  

  -webkit-animation: borderBlink 0.5s step-end infinite;    
  animation: borderBlink 0.5s step-end infinite;    
}
/* .borderBlink:hover {    
  -webkit-animation: borderBlink 0.5s step-end infinite;    
  animation: borderBlink 0.5s step-end infinite;    
} */
/* @end */


/* @font-face {
  font-family: 'NotoSans';
  font-weight: 900;
  src: local('NotoSans'), url(./fonts/NotoSans-Bold.ttf) format('truetype');
} */

.text-in-banner-pe {
  z-index: 20;
  /* font-size: 1.2rem; */
  font-size: 20px;
  font-weight: bolder;
  position: absolute;
  /* left: 24rem; */
  right: 150px;
  top: 7.65rem;
  /* left: 100px;
  top: 100px; */  
}

/* 2021.04.08 - disable picture dragging or right-click */
img {
  pointer-events: none;
}

/* 2021.04.10 */
.sticky-header .th {
  position: sticky;
  top: 0px;
}

/* 2021.04.10 */
.sticky {
  position: sticky;
  top: -1px;
  background-color: white;
  border: 0px white solid;
  z-index: 20;
}

/*** 2021.04.12 - custom scrollbar ***/
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
} 

/* 2021.04.14 - button as link */
.btn-link {
  background: none!important;
  border: none;
  padding: 0%;
  padding-left: 2px;
  padding-right: 2px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.btn-link:hover {
  background: none!important;
  border: none;
  padding: 0%;
  padding-left: 2px;
  padding-right: 2px;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.blink-fast {
	-webkit-animation: blink 0.5s linear infinite;
	-moz-animation: blink 0.5s linear infinite;
	-ms-animation: blink 0.5s linear infinite;
	-o-animation: blink 0.5s linear infinite;
	 animation: blink 0.5s linear infinite;
}

.dropdown-toggle {
  width: 90%;
}

.btn {
  box-shadow: none !important;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
}

.error-validation {
  color: #888;
  font-size: 14px;
  padding: 5px 0 0 0;
  margin-top: 5px;
  border: 2px dotted #61dafb;
  border-radius: 5px;
}

